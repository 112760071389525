
html,
body {
  background-color: #e5e5e5;
  /* font style to poppins */
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

.form_area {
  /* height: 100%; */
  /* padding: 20px; */
  background-color: #e5e5e5;
}
#labels {
  padding: 0.5rem;
  font-weight: 600;
  font-size: 1.2rem;
}


.btn {
  position: relative;
  float: right;
}


.active{
  color: #0B63F8;
}

.sidenav a {
  
  text-decoration: none;
  font-size: 1.3rem;
  color: #302222;
}
.sidenav a:hover {
  color:#3262b6;
}

.sidenav a{
  padding:0.5rem;
}

.main-content{
  background-color: #e5e5e5;
}



a {
  display: inline;
  font-size: 1rem;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.center{
  display: flex;
  justify-content: space-between;
}

.clickable-blue {
  color: #0B63F8;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

/* hover on clickable blue change the color slightly */

.clickable-blue:hover{
  color: rgb(66, 66, 217);
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.event-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list-view{
  flex-grow: 1;
  overflow-y: auto;
}

#checkbox{
padding: 1rem;
}
